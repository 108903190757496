import {MassEditBasicInfoVideoInformation} from '../../../interfaces/videoMassEditBasicInfo';

// eslint-disable-next-line import/prefer-default-export
export const defaults = {
    id: '',
    mcbt: '',
    version: null,
    sku: '',
    themeId: null,
    typeId: null,
    addAssetLabels: '',
    isMadeForKids: false,
    note: '',
    'upload.filename': '',
    'upload.customId': '',
    'upload.customThumbnail': '',
    'upload.videoId': '',
    'upload.uploadPolicyId': null,
    'upload.categoryId': null,
    'upload.privacy': null,
    'upload.notifySubscribers': false,
    'upload.seriesGroupId': null,
    'upload.seasonSubgroupId': null,
    'upload.keywords': '',
    'upload.uploadVersion': null,
} as MassEditBasicInfoVideoInformation;
