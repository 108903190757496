import {defaults} from '../../configs/pages/mass-edit-basic-info/defaults';
import {DataGridValue} from '../../interfaces/dataGrid';
import {MassEditBasicInfoVideoInformation} from '../../interfaces/videoMassEditBasicInfo';
import validateYoutubeVideoTags from '../validators/youtubeVideoTags';
import validateYoutubeVideoId from '../validators/youtubeVideoId';

// eslint-disable-next-line import/prefer-default-export
export const applyDefaultValuesFallback = (
    values: MassEditBasicInfoVideoInformation[],
) => {
    const copiedValues = JSON.parse(JSON.stringify(values));

    return copiedValues.map((valuesRow: MassEditBasicInfoVideoInformation) => {
        const newRow = JSON.parse(JSON.stringify(valuesRow));

        Object.keys(valuesRow).forEach((rowKey: string) => {
            if (
                valuesRow[rowKey as keyof MassEditBasicInfoVideoInformation]
                    === null
                && defaults[rowKey as keyof MassEditBasicInfoVideoInformation]
                    !== undefined
            ) {
                newRow[rowKey] = defaults[rowKey as keyof MassEditBasicInfoVideoInformation];
            }
        });

        return newRow;
    });
};

export const videoPropertyValidation = (validate: {
    property: keyof MassEditBasicInfoVideoInformation,
    value: DataGridValue,
}) => {
    const validationResult = {
        isValid: true,
        message: '',
    };

    if (
        defaults[validate.property] !== undefined
        && validate.value === defaults[validate.property]
    ) {
        return validationResult;
    }

    if (typeof validate.value !== 'string') {
        validationResult.isValid = false;
        validationResult.message = 'Incorrect value';

        return validationResult;
    }

    switch (validate.property) {
        case 'version':
        case 'sku':
        case 'themeId':
        case 'typeId':
        case 'addAssetLabels':
        case 'isMadeForKids':
        case 'upload.uploadPolicyId':
        case 'upload.categoryId':
        case 'upload.privacy':
        case 'upload.notifySubscribers':
        case 'upload.seriesGroupId':
        case 'upload.seasonSubgroupId':
        case 'upload.uploadVersion':
            // No specific validation rules for these properties
            break;
        case 'mcbt':
            if (validate.value.length > 255) {
                validationResult.isValid = false;
                validationResult.message = "MCBT can't be longer than 255 characters";
            }

            break;
        case 'note':
            if (validate.value.length > 255) {
                validationResult.isValid = false;
                validationResult.message = "Note can't be longer than 255 characters";
            }

            break;
        case 'upload.filename':
            if (!/^.*\.(mp4|mov|avi)$/.test(validate.value)) {
                validationResult.isValid = false;
                validationResult.message = 'Not valid video file format (.mp4, .mov or .avi only)';
            } else if (validate.value.length > 255) {
                validationResult.isValid = false;
                validationResult.message = "Filename can't be longer than 255 characters";
            }

            break;
        case 'upload.customId':
            if (validate.value.length > 255) {
                validationResult.isValid = false;
                validationResult.message = "Custom ID can't be longer than 255 characters";
            }

            break;
        case 'upload.customThumbnail':
            if (!/^.*\.(jpg|png|jpeg|gif)$/.test(validate.value)) {
                validationResult.isValid = false;
                validationResult.message = 'Not valid image file format (.jpg, .jpeg, .png or .gif)';
            } else if (validate.value.length > 255) {
                validationResult.isValid = false;
                validationResult.message = "Custom Thumbnail can't be longer than 255 characters";
            }

            break;
        case 'upload.keywords': {
            const tagsValidation = validateYoutubeVideoTags(validate.value);

            if (tagsValidation !== true) {
                validationResult.isValid = false;
                validationResult.message = tagsValidation;
            }

            break;
        }
        case 'id':
        case 'upload.videoId': {
            if (validate.value.length === 0) {
                break;
            }

            const videoIdValidation = validateYoutubeVideoId(validate.value);

            if (videoIdValidation !== true) {
                validationResult.isValid = false;
                validationResult.message = videoIdValidation;
            }

            break;
        }
        default:
            validationResult.message = 'No validation criteria';
    }

    return validationResult;
};
